import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  opacity: 0.8;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`

const InternalStaff = () => {
  const [isTagged, setIsTagged] = useState(false)

  useEffect(() => {
    if (!window || !window.localStorage) return
    const tagged = window.localStorage.getItem('internalStaff')
    setIsTagged(tagged === 'true')
  }, [])

  const handleTagging = () => {
    if (window && window.localStorage) {
      window.localStorage.setItem('internalStaff', 'true')
      setIsTagged(true)
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '65vh',
          flexDirection: 'column',
        }}
      >
        <h1>Welcome, Internal Staff Member</h1>
        {isTagged ? (
          <p style={{ color: 'green', fontSize: '20px' }}>
            You have been tagged as an internal staff member and successfully
            opted out of Google Analytics reporting for your organization’s
            site.
          </p>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p>
              You are currently not tagged as an internal staff member. If you
              are an internal staff member, please click the button below to tag
              yourself.
            </p>
            <p>
              Please do this on all devices and browsers you use to access this
              website.
            </p>
            <Button type="button" onClick={handleTagging}>
              Tag me as an internal staff member
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default InternalStaff
